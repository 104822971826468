export const _url = {
  //server: 'http://localhost:5197/',
  server: 'https://api.topjob360.com/',

  apiLogin: 'Account/login',
  apiRegister: 'Account/register',
  apiRefreshToken: 'Account/refresh',
  apiForgotPassword: 'Account/forgotPassword',
  apiResetPassword: 'Account/resetPassword',
  apiChangePassword: 'Account/changepassword',
  apiUpdateProfile: 'Account/updatecv',
  apiUpdateAvatar: 'Account/updateAvatar',
  apiUpdateImage: 'Account/updateImage',
  apiGetAccountInfo: 'Account/me',
  apiGetUserInfo: 'Account/u',
  apiGetAllEmployee: 'Account/employees',
  apiConfirmEmail: 'Account/confirmEmail',
  apiChangeUserName: 'Account/changeusername',
  apiViewCompany: 'Account/company',
  apiGetAllCompany: 'Account/companies',
  apiUpdateFile: 'Account/updateFile',
  apiGetMyFile: 'Account/myFile',
  apiDownloadFile: 'Account/download',

  apiGetListCities: 'Country/ListCities',
  apiMaster: 'Master/Get',

  apiUpdateCompany: 'Account/updatecompany',

  apiCreateJob: 'Jobs/create',
  apiUpdateJob: 'Jobs/update',
  apiGetListJob: 'Jobs/getList',
  apiGetListJobAll: 'Jobs/all',
  apiGetMyJob: 'Jobs/myJobs',
  apiGetMyApplied: 'Jobs/Myapplied',
  apiJobByUser: 'Jobs/getListByUser',
  apiDeleteJob: 'Jobs/delete',
  apiActive: 'Jobs/active',
  apiDeactive: 'Jobs/deactive',
  apiApplyJob: 'Jobs/apply',
  apiAppliedJob: 'Jobs/applied',
  apiApplyChange: 'Jobs/apply_change',
  apiJobDownloadFile: 'Jobs/downloadFile',
  apiGetListJobHome: 'Jobs/home',
  apiGetMysuggestion: 'Account/mysuggestion',
  apiJobByCompany: "Jobs/company/",

  //category
  apiCreateCategory: 'Categories/create',
  apiUpdateCategory: 'Categories/update',
  apiGetCategory: 'Categories/',
  apiDeleteCategory: 'Categories/delete',

  //visa
  apiGetVisa: 'Country/Visas',
  apiVisaMod: 'Country/Visa/Mod',

  //country/city
  apiCountryList: 'Country/List',
  apiCountryMod: 'Country/Mod',
  apiCityList: 'Country/ListCities',
  apiCityMod: 'Country/City/Mod',
  //Blog
  
  apiListBlog : 'Blog/list',
  apiviewBlog : 'Blog/view',
  //language
  apiGetLanguage: 'Country/Languages',
  apiLanguageMod: 'Country/Language/Mod',
}

export const siteKey = "6LcpjyAqAAAAAIobc52-XkoDhjwKbECm4GJuempX";
// Set time out for API Server
const timeOutNumber = 60;

export const timeout = (promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error("timeout"))
    }, timeOutNumber * 1000)
    promise.then(resolve, reject)
  })
}
