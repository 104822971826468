import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getViewCompany } from "../../../api/apiAccount";
import { GetCountryList } from "../../../api/apiCountry";
import { getJobByCompany } from "../../../api/apiJob";
import JobByCompany from "../../../common/jobAllList/JobByCompany";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import { convertExperience } from "../../../common/checkValidate/convertExperience";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { checkIconJobPost } from "../../../common/checkValidate/checkIconJobPost";
import { jobTypeList } from "../../../common/dropdownList/dropdownData";
import Pagination from "../../../pages/Jobs/Pagination/Pagination";
//Import images
import featureImage from "../../../assets/images/featured-job/img-01.webp";
//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
//Import BlogImage
import blogImage1 from "../../../assets/images/blog/img-01.jpg";
import blogImage3 from "../../../assets/images/blog/img-03.jpg";
import blogImage12 from "../../../assets/images/blog/img-12.jpg";
import ImageUrgent from "../../../assets/images/icon-job/urgent.webp";

const CompanyViewContent = () => {
  const { t } = useTranslation("translation");
  let [avatar, setAvatar] = useState('');
  let [images, setImage] = useState([blogImage1, blogImage3, blogImage12]);
  let [image1, setImage1] = useState('');
  let [image2, setImage2] = useState('');
  let [image3, setImage3] = useState('');
  let [fullname, setFullName] = useState('');
  let [username, setUserName] = useState('');
  let [industry, setIndustry] = useState('');
  let [email, setEmail] = useState('');
  let [codeArea, setCodeArea] = useState('+84');
  let [phone, setPhone] = useState('');
  let [address, setAddress] = useState('');
  let [vocative, setVocative] = useState('Mr.');
  let [contactperson, setContactPerson] = useState('');
  let [website, setWebsite] = useState('');
  let [facebook, setFacebook] = useState('');
  let [twitter, setTwitter] = useState('');
  let [instagram, setInstagram] = useState('');
  let [whatsapp, setWhatsapp] = useState('');
  let [aboutcompany, setAboutCompany] = useState('');
  let [editorState, setEditorState] = useState(EditorState.createEmpty());
  let [jobRecruiting, setJobRecruiting] = useState([]);
  let [alertNoData, setAlertNoData] = useState('');
  let [totalPage, setTotalPage] = useState(1);

  //Lightbox
  const [photoIndex, setphotoIndex] = useState(0);
  const [isGallery, setisGallery] = useState(false);

  let { pathname } = useLocation();

  useEffect(() => {
    let params = pathname.split('/');
    let username = params[2];

    getViewCompany(username).then((res) => {
      if (res) {
        res.avatar ? setAvatar(res.avatar) : setAvatar('');
        res.fullName ? setFullName(res.fullName) : setFullName('');
        res.userName ? setUserName(res.userName) : setUserName('');
        res.email ? setEmail(res.email) : setEmail('');
        res.address ? setAddress(res.address) : setAddress('');
        if (res.phone) {
          let phoneData = res.phone.split('|');
          phoneData[0] ? setCodeArea(phoneData[0]) : setCodeArea('+84');
          phoneData[1] ? setPhone(phoneData[1]) : setPhone('');
        }
        if (res.candidateData.contactPersonName) {
          let contact = res.candidateData.contactPersonName.split('.');
          setVocative(contact[0] + '.');
          setContactPerson(contact[1]);
        }
        res.candidateData.image1 ? setImage1(res.candidateData.image1) : setImage1('');
        res.candidateData.image2 ? setImage2(res.candidateData.image2) : setImage2('');
        res.candidateData.image3 ? setImage3(res.candidateData.image3) : setImage3('');
        let imageshow1 = res.candidateData.image1 ? res.candidateData.image1 : blogImage1;
        let imageshow2 = res.candidateData.image2 ? res.candidateData.image2 : blogImage3;
        let imageshow3 = res.candidateData.image3 ? res.candidateData.image3 : blogImage12;
        setImage([imageshow1, imageshow2, imageshow3]);
        if (res.website) {
          let websiteData = res.website.split('|');
          websiteData[0] ? setWebsite(websiteData[0]) : setWebsite("");
          websiteData[1] ? setFacebook(websiteData[1]) : setFacebook("");
          websiteData[2] ? setInstagram(websiteData[2]) : setInstagram("");
          websiteData[3] ? setTwitter(websiteData[3]) : setTwitter("");
          websiteData[4] ? setWhatsapp(websiteData[4]) : setWhatsapp("");
        }
        res.about ? (setAboutCompany(res.about)) : setAboutCompany('');
        if (res.about) {
          const blocksFromHTML = htmlToDraft(res.about);
          const content = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(content));
        }

        GetCountryList().then((result) => {
          if (result.category) {
            result.category.map((item, key) => {
              if (res.candidateData.industry) {
                if (item.id == res.candidateData.industry) {
                  setIndustry({ value: item.id, label: item.nameVi });
                }
              }
            });
          }
        });

        getJobByCompany(username, 1).then((result) => {
          const job = [];
          if (result.data && result.data.length) {
            setTotalPage(result.total);
            result.data.map((item, key) => {
              job.push({
                id: item.id,
                companyImg: res.avatar,
                jobDescription: item.title,
                experience: convertExperience(item.experienceRequired),
                companyName: item.company ? item.company : res.fullName,
                location: item.workLocationAddress,
                salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency ,
                fullTime: (item.jobType === 1 ? false : true),
                partTime: (item.jobType === 1 ? true : false),
                dateNeeded: item.dateNeeded,
                iconJobPost: checkIconJobPost(item.dateNeeded) == 2 ? ImageUrgent : "",
                jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
                addclassNameBookmark: false,
                badges: [],
              });
            });
            setJobRecruiting(job);
            
          } else {
           
            setJobRecruiting([]);
          };
          if (result.total == 0) {
            setAlertNoData(t('Không có việc làm đang tuyển dụng'));
          } else {
            setAlertNoData('');
          }
        })
      };
    });
  }, []);

  const loadJobByCompanyData = (page) => {
    getJobByCompany(username, page).then((res) => {
      const job = [];
      if (res.data.length) {
        setTotalPage(res.total);
        res.data.map((item, key) => {
          job.push({
            id: item.id,
            companyImg: avatar,
            jobDescription: item.title,
            experience: convertExperience(item.experienceRequired),
            companyName: item.company ? item.company : fullname,
            location: item.workLocationAddress,
            salary: numberFormat(item.salaryFrom) + ' - ' + numberFormat(item.salaryTo) + ' ' + item.currency ,
            fullTime: (item.jobType === 1 ? false : true),
            partTime: (item.jobType === 1 ? true : false),
            dateNeeded: item.dateNeeded,
            iconJobPost: checkIconJobPost(item.dateNeeded) == 2 ? ImageUrgent : "",
            jobTypeText: (item.jobType === 1 ? jobTypeList[2].label : (item.jobType === 0 ? jobTypeList[1].label : jobTypeList[0].label)),
            addclassNameBookmark: false,
            badges: [],
          });
        });
        setJobRecruiting(job);
      };
    })
  }

  const updateCurrentPage = (page) => {
    loadJobByCompanyData(page);
  }

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              <div className="text-center">
                <div className="mb-4 profile-user">
                  <img
                    src={avatar ? avatar : featureImage}
                    className="rounded-circle img-thumbnail profile-img"
                    id="profile-img"
                    alt=""
                  />
                </div>
              </div>
              <h6 className="fs-18 mb-1 mt-4">{fullname}</h6>
              <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-twitter-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-semibold mb-3">{t("Giới thiệu")}</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("Ngành nghề")}</label>
                  <div>
                    <p className="text-muted mb-0">{industry.label}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("Liên hệ")}</label>
                  <div>
                    <p className="text-muted mb-0">{vocative}{contactperson}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("Địa chỉ")}</label>
                  <div>
                    <p className="text-muted mb-0">{address}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("Website")}</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      {website}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("Điện thoại")}</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      {'(' + codeArea + ') ' + phone}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">{t("Email")}</label>
                  <div>
                    <p className="text-muted text-break mb-0">
                      {email}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-3">
              <Link to="#" className="btn btn-primary btn-hover w-100">
                <i className="uil uil-phone"></i> {t("Liên hệ")}
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>

      {isGallery ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % images.length);
          }}
          imageCaption={ parseFloat(photoIndex + 1)}
        />
      ) : null}
      <Col lg={8}>
        <Card className="ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4">
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">{t("Giới thiệu")}</h6>
              <div dangerouslySetInnerHTML={{ __html: aboutcompany }} />
            </div>
            <div className="candidate-portfolio mb-5">
              <h6 className="fs-17 fw-semibold mb-4">{t("Hình ảnh")}</h6>
              <Row className="g-3">
                <Col lg={4}>
                  <div className="mb-4 profile-user1">
                    <img
                      src={image1 ? image1 : blogImage1}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(0);
                      }}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4 profile-user2">
                    <img
                      src={image2 ? image2 : blogImage3}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(1);
                      }}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4 profile-user3">
                    <img
                      src={image3 ? image3 : blogImage12}
                      onClick={() => {
                        setisGallery(true);
                        setphotoIndex(2);
                      }}
                      alt=""
                      style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <h6 className="fs-17 fw-semibold mb-4">{t("Việc làm đang tuyển")}</h6>
              {alertNoData && <div className="section-title text-center">
                <h3 className="title">{alertNoData}</h3>
              </div>}
              {(!jobRecruiting.length && !alertNoData) ? (<PlaceholderJobFilter />) : <JobByCompany jobRecruiting={jobRecruiting} />}
              {jobRecruiting.length > 0 && <Pagination totalPage={totalPage} currentPage={updateCurrentPage} />}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CompanyViewContent;