import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Ago from "../../components/ago";

const JobByCompany = ({ jobRecruiting }) => {
    const { t } = useTranslation("translation");
    let jobDetail = '/job/';

    return (
        <React.Fragment>
            {jobRecruiting && jobRecruiting.map((jobRecruitingDetails, key) => (
                <div
                    key={key}
                    className={
                        jobRecruitingDetails.addclassNameBookmark === true
                            ? "job-box bookmark-post card mt-4"
                            : "job-box card mt-4"
                    }
                >
                    {
                        jobRecruitingDetails.iconJobPost && <div className="bookmark-label text-center">
                            <Link to="#" className="text-white align-middle">
                                <img
                                    src={jobRecruitingDetails.iconJobPost}
                                    alt=""
                                    className="img-fluid rounded-3"
                                    style={{ width: '50px', height: '40px', objectFit: 'cover' }}
                                />
                            </Link>
                        </div>
                    }
                    <div className="p-4">
                        <Row>
                            <Col md={2}>
                                <Link to={jobDetail + jobRecruitingDetails.id}>
                                    <img
                                        src={jobRecruitingDetails.companyImg}
                                        alt=""
                                        className="img-fluid rounded-3"
                                        style={{ width: '100px', height: '80px', objectFit: 'cover' }}
                                    />
                                </Link>
                            </Col>
                            <Col md={10}>
                                <div className="mt-3 mt-lg-0">
                                    <h5 className="fs-17 mb-1">
                                        <Link to={jobDetail + jobRecruitingDetails.id} className="text-dark">
                                            {jobRecruitingDetails.jobDescription}
                                        </Link>{" "}
                                        <small className="text-muted fw-normal">
                                            ({jobRecruitingDetails.experience})
                                        </small>
                                    </h5>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <p className="text-muted fs-14 mb-0">
                                                {jobRecruitingDetails.companyName}
                                            </p>
                                        </li>
                                        <li className="list-inline-item">
                                            <p className="text-muted fs-14 mb-0">
                                                <i className="mdi mdi-map-marker"></i>
                                                {jobRecruitingDetails.location}
                                            </p>
                                        </li>
                                        <li className="list-inline-item">
                                            <p className="text-muted fs-14 mb-0">
                                                <i className="uil uil-wallet"></i>{" "}
                                                {jobRecruitingDetails.salary} / {t("tháng")}
                                            </p>
                                        </li>
                                    </ul>
                                    <div className="mt-2">
                                        <span
                                            className={
                                                jobRecruitingDetails.fullTime === true
                                                    ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                                    : jobRecruitingDetails.partTime === true
                                                        ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                                        : jobRecruitingDetails.freeLance === true
                                                            ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                                            : jobRecruitingDetails.internship === true
                                                                ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                                                : ""
                                            }
                                        >
                                            {jobRecruitingDetails.jobTypeText}
                                        </span>
                                        {(jobRecruitingDetails.badges || []).map(
                                            (badgeInner, key) => (
                                                <span
                                                    className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                                                    key={key}
                                                >
                                                    {badgeInner.badgeName}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="favorite-icon">
                            <Link to="#">
                                <i className="uil uil-heart-alt fs-18"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="p-3 bg-light">
                        <div className="row justify-content-between">
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                                <div>
                                    <Ago date={jobRecruitingDetails.dateNeeded} />
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="text-md-end">
                                    <Link
                                        to={jobDetail + jobRecruitingDetails.id}
                                        onClick=""
                                        className="primary-link"
                                    >
                                        {t("companydetail.ungtuyen")}{" "}
                                        <i className="mdi mdi-chevron-double-right"></i>
                                    </Link>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}
export default JobByCompany;