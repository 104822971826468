import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { confirmEmail } from "../../api/apiAccount";
import { useTranslation } from "react-i18next";
//import Images
import Subscribe from "../../assets/images/subscribe.png";

const ActiveEmail = () => {
  const { t } = useTranslation("translation");
  document.title = t("Kích hoạt tài khoản");
  let [success, setSuccess] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const userId = query.get('userId');
    const code = query.get('code');
    confirmEmail(userId, code).then((res) => {
      if (res.status === 200){
        setSuccess( t('Kích hoạt tài khoản thành công!'));
      } else {
        setSuccess(t('Kích hoạt tài khoản thất bại!'));
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-error bg-auth text-dark">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={6}>
                    <div className="text-center">
                      <img src={Subscribe} alt="" className="img-fluid" />
                      <div className="mt-5">
                        <h4 className="text-uppercase mt-3">
                          {success}
                        </h4>
                        <div className="mt-4">
                          <Link
                            className="btn btn-primary waves-effect waves-light"
                            to="/"
                          >
                            <i className="mdi mdi-home"></i> {t("Quay lại trang chủ")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActiveEmail;
