import React from "react";
import { Container } from "reactstrap";
import CompanyDetails from "./CompanyDetails";
import PageHeader from "../../Component/PageHeader";
import { useTranslation } from "react-i18next";

const CompanyList = () => {
  const { t } = useTranslation("translation");
  document.title = t("Danh sách công ty");
  return (
    <React.Fragment>
      <PageHeader title= {t("Danh sách công ty")} />
      <section className="section">
        <Container>
          <CompanyDetails />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyList;
