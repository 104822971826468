import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Icon } from '@iconify/react';
import { useTranslation } from "react-i18next";
import { checkEmailFormat } from "../../common/checkValidate/checkEmail";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { setStorage } from "../../common/constants/Services";
import { MESSAGE_VALIDATE } from "../../common/constants/Messages";
import { login } from "../../api/apiAccount";
import { Link, useNavigate } from "react-router-dom";
import Alerts from "../../pages/ExtraPages/Components/Alerts";
import Spinners from "../../pages/ExtraPages/Components/Spinners";
import ScrolltoTop from "../../components/ScrolltoTop";
import loginImage from "../../assets/images/login.webp";
import bannerImage from "../../assets/images/banner.webp";

const SignIn = () => {
  const { t } = useTranslation("translation");
  document.title = t("signin.dangnhap");

  const didMount = useRef(false);
  const navigate = useNavigate();


  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [passwordtype, setPasswordType] = useState('password');
  let [invalid, setInvalid] = useState(true);

  let [emailErr, setEmailErr] = useState('');
  let [passwordErr, setPasswordErr] = useState('');
  let [alertdanger, setAlertDanger] = useState(false);
  let [loading, setLoading] = useState(false);

  const handleEmail = event => {
    setEmail(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('email');
  }, [email]);

  const handlePassword = event => {
    setPassword(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('password');
  }, [password]);

  const handleShowPassword = () => {
    passwordtype === 'password' ? setPasswordType('text') : setPasswordType('password');
  }

  const handleLogin = () => {
    setAlertDanger(false);
    setLoading(true);
    login(email, password).then((res) => {
      if (res.accessToken) {
        setStorage(STORAGE_KEYS.ACCESS_TOKEN, res.accessToken);
        setStorage(STORAGE_KEYS.REFRESH_TOKEN, res.refreshToken);
        let expiredTime = new Date();
        const seconds = expiredTime.getSeconds() + res.expiresIn;
        expiredTime.setSeconds(seconds);
        setStorage(STORAGE_KEYS.EXPIRED_TOKEN, expiredTime.getTime());

        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        setAlertDanger(true);
      }
    }, () => {
      setLoading(false);
      setAlertDanger(true);
    })
  }

  const checkValidate = (value) => {
    let checkEmail = true;
    let checkPass = true;
    if (value === 'email') {
      if (!checkEmailFormat(email)) {
        setEmailErr(t("Định dạng email chưa chính xác"));
        checkEmail = true;
      } else {
        setEmailErr('');
        checkEmail = false;
      }
      !password ? checkPass = true : checkPass = false;
    }
    if (value === 'password') {
      if (!password) {
        setPasswordErr(t("Vui lòng nhập mật khẩu"));
        checkPass = true;
      } else {
        setPasswordErr('');
        checkPass = false;
      }
      !checkEmailFormat(email) ? checkEmail = true : checkEmail = false;
    }
    (checkEmail || checkPass) ? setInvalid(true) : setInvalid(false);
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container >
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={bannerImage}
                                alt=""
                                className="logo-light w-100"
                              />
                              <img
                                src={loginImage}
                                alt=""
                                className="logo-light w-100"
                              />
                              <img
                                src={bannerImage}
                                alt=""
                                className="logo-dark w-100"
                              />
                              <img
                                src={loginImage}
                                alt=""
                                className="logo-dark w-100"
                              />
                            </Link>

                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          {loading && <Spinners />}
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>{t("signin.chaomungban")}</h5>

                              </div>
                              <div className="auth-form">
                                <div className="mb-3">
                                  <label
                                    htmlFor="usernameInput"
                                    className="form-label"
                                  >
                                    {t("signup.email")}
                                  </label>
                                  <div className="position-relative">
                                    <Input
                                      autoComplete="off"
                                      type="text"
                                      className="form-control bg-light text-dark"
                                      id="usernameInput"
                                      placeholder={t("signup.nhapemail")}
                                      onChange={handleEmail}
                                      value={email}
                                      style={{ paddingLeft: 40 }}
                                    />
                                    <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green', fontSize: '20px' }} icon="iconamoon:email-light" />


                                  </div>
                                  {emailErr && <label style={{ color: '#FF6347', fontSize: 14 }}>{emailErr}</label>}
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    {t("signup.matkhau")}
                                  </label>
                                  <div className="position-relative">
                                    <Input
                                      type={passwordtype}
                                      className="form-control bg-light text-dark"
                                      id="passwordInput"
                                      placeholder={t("signup.nhapmatkhau")}
                                      onChange={handlePassword}
                                      value={password}
                                      style={{ paddingLeft: 40 }}
                                    />
                                    <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: 'green', fontSize: '20px' }} icon="gravity-ui:shield-keyhole" />

                                  </div>
                                  <p style={{ float: "right", position: "relative", marginTop: -33, marginRight: 10, color: 'black' }} onClick={handleShowPassword}>
                                    {passwordtype === "password" ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                  </p>
                                  {passwordErr && <label style={{ color: '#FF6347', fontSize: 14 }}>{passwordErr}</label>}
                                </div>
                                <div className="mb-4">
                                  <div className="form-check">
                                    <Link
                                      to="/resetpassword"
                                      className="float-end text-white"
                                    >
                                      {t("signin.quenmatkhau")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-white btn-hover w-100"
                                    onClick={handleLogin}
                                    disabled={invalid}
                                  >
                                    {t("signin.dangnhap")}
                                  </button>
                                </div>
                              </div>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  {t("signin.chuacotaikhoan")}{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    {t("signin.dangky")}{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                      {alertdanger && <Alerts message={MESSAGE_VALIDATE.login_invalid} />}
                    </Card>
                  </Col>
                </Row>
                <ScrolltoTop />
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
