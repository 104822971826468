import React from "react";
import { Container, Row } from "reactstrap";
import CompanyContent from "./CompanyContent";
import Section from "./Section";
import { useTranslation } from "react-i18next";
const CompanyProfile = () => {
   const { t } = useTranslation("translation");
  document.title = t("Thông tin nhà tuyển dụng");
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container fluid>
          <Row>
            <CompanyContent />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyProfile;
