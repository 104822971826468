import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { GetCountryList } from "../../../api/apiCountry";
import JobListing from "./JobListing";
import Section from "./Section";

const ManageJobs = () => {
  const { t } = useTranslation("translation");
  document.title = t("managejob.danhsachcongviec");

  let [countryId, setCountryId] = useState(1);
  let [countryList, setCountryList] = useState([]);
  let [countryListAll, setCountryListAll] = useState();
  let [nationalityList, setNationalityList] = useState([]);
  let [cityId, setCityId] = useState(1);
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [countryFilterList, setCountryFilterList] = useState();
  let [nationality, setNationality] = useState('');
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  let [industryFilterList, setIndustryFilterList] = useState();
  let [field, setField] = useState('');
  let [fieldsList, setFieldsList] = useState();
  let [visaTypeList, setVisaTypeList] = useState();
  let [foreignLanguagesList, setForeignLanguagesList] = useState();
  let [foreignLanguagesList2, setForeignLanguagesList2] = useState();
  let [foreignLanguagesList3, setForeignLanguagesList3] = useState();
  let [foreignLanguage, setForeignLanguage] = useState('');
  let [foreignLanguageLevel, setforeignLanguageLevel] = useState('');
  let [foreignLanguage2, setForeignLanguage2] = useState('');
  let [foreignLanguageLevel2, setforeignLanguageLevel2] = useState('');
  let [foreignLanguage3, setForeignLanguage3] = useState('');
  let [foreignLanguageLevel3, setforeignLanguageLevel3] = useState('');
  let [foreignLanguageLevelList, setForeignLanguageLevelList] = useState();
  let [foreignLanguageLevelList2, setForeignLanguageLevelList2] = useState();
  let [foreignLanguageLevelList3, setForeignLanguageLevelList3] = useState();
  let [foreignLanguageLevelAll, setForeignLanguageLevelAll] = useState();
  let [foreignLanguageAll, setForeignLanguageAll] = useState();
  let [currency, setCurrency] = useState('VND');
  let [currencyList, setCurrencyList] = useState();


  useEffect(() => {
    GetCountryList().then((result) => {
      if (result.country) {
        const countryArr = [];
        const nationalityArr = [{ value: 0, label: t('Bất kỳ') }];
        const countryFilterArr = [{ value: 0, label: t('Quốc gia') }];
        const cityArr = [];
        const cityListAllArr = [];
        const currencyArr = [];
        const visaAllArr = [{ value: 0, label: t('Không yêu cầu')}];
        result.country.map((item, key) => {
          if (key === 0) {
            setCountryId({ value: item.id, label: item.name, currency: item.currency, visas: item.visas });
            setNationality({ value: item.id, label: item.name });
            setCurrency({ value: item.currency, label: item.currency });
            let cities = item.cities.filter(c => c.countryID === item.id);
            if (cities.length > 0) {
              cities.map((itemcity, keycity) => {
                if (keycity === 0) {
                  setCityId({ value: itemcity.id, label: itemcity.name });
                }
                cityArr.push({ value: itemcity.id, label: itemcity.name });
              })
              setCityList(cityArr);
            }
          }
          if (item.currency) {
            currencyArr.push({ value: item.currency, label: item.currency });
          }
          if (item.visas.length) {
            item.visas.map((itemvisa, keyvisa) => {
              visaAllArr.push({ value: itemvisa.id, label: itemvisa.name, countryId: itemvisa.countryId });
            })
          }
          countryArr.push({ value: item.id, label: item.name, currency: item.currency, visas: item.visas });
          nationalityArr.push({ value: item.id, label: item.name });
          countryFilterArr.push({ value: item.id, label: item.name });
          cityListAllArr.push(item.cities);
        })
        setVisaTypeList(visaAllArr);
        setCurrencyList(currencyArr);
        setCountryListAll(result.country);
        setCountryList(countryArr);
        setNationalityList(nationalityArr);
        setCountryFilterList(countryFilterArr);
        setCityListAll(cityListAllArr);
      };
      if (result.category) {
        const industryListArr = [];
        const industryFilterArr = [{ value: 0, label: t('Ngành nghề') }];
        const fieldArr = [];
        result.category.map((item, key) => {
          if (key === 0) {
            setIndustry({ value: item.id, label: item.nameVi });
            item.children.map((item2, key2) => {
              if (key2 === 0) {
                setField({ value: item2.id, label: item2.nameVi });
              }
              fieldArr.push({ value: item2.id, label: item2.nameVi });
            })
          }
          industryListArr.push({ value: item.id, label: item.nameVi, children: item.children });
          industryFilterArr.push({ value: item.id, label: item.nameVi });
        });
        setIndustryList(industryListArr);
        setIndustryFilterList(industryFilterArr);
        setFieldsList(fieldArr);
      };
      if (result.language) {
        let languageData = result.language.filter((value, index, self) => self.findIndex((l) => l.countryId === value.countryId) === index);

        const languageListArr = [{ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') }];
        languageData.map((item, key) => {
          let languageDefine = '';
          if (item.levelCode === "VN") {
            languageDefine = t('Tiếng Việt');
          }
          if (item.levelCode === "JP") {
            languageDefine = t('Tiếng Nhật');
          }
          if (item.levelCode === "KR") {
            languageDefine = t('Tiếng Hàn');
          }
          if (item.levelCode === "GER") {
            languageDefine = t('Tiếng Đức');
          }
          if (item.levelCode === "EN") {
            languageDefine = t('Tiếng Anh');
          }
          if (item.levelCode === "CN") {
            languageDefine = t('Tiếng Trung');
          }
          if (item.levelCode === "SA") {
            languageDefine = t('Tiếng Ả Rập');
          }
          if (item.levelCode === "RU") {
            languageDefine = t('Tiếng Nga');
          }
          languageListArr.push({ value: item.id, label: languageDefine, levelCode: item.levelCode, levelName: item.levelName });
          if (key === 0) {
            setForeignLanguage({ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName:t('Không yêu cầu') });
            setForeignLanguage2({ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName:t('Không yêu cầu')});
            setForeignLanguage3({ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') });
          }
        });
        setForeignLanguagesList(languageListArr);
        setForeignLanguagesList2(languageListArr);
        setForeignLanguagesList3(languageListArr);

        const languageLevelListArr = [{ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') }];
        const languageLevelAllListArr = [{ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') }];
        result.language.map((item, key) => {
          if (key === 0) {
            setforeignLanguageLevel({ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') });
            setforeignLanguageLevel2({ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') });
            setforeignLanguageLevel3({ value: 0, label: t('Không yêu cầu'), levelCode: '', levelName: t('Không yêu cầu') });
          }
          if (item.levelCode === "JP") {
            languageLevelListArr.push({ value: item.id, label: item.levelName, levelCode: item.levelCode });
          }
          languageLevelAllListArr.push({ value: item.id, label: item.levelName, levelCode: item.levelCode });
        });
        setForeignLanguageLevelList(languageLevelListArr);
        setForeignLanguageLevelList2(languageLevelListArr);
        setForeignLanguageLevelList3(languageLevelListArr);
        setForeignLanguageLevelAll(languageLevelAllListArr);
        setForeignLanguageAll(result.language);
      }
    });
  }, [])

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          {foreignLanguageAll && <JobListing countryId={countryId} countryList={countryList} countryListAll={countryListAll}
            nationalityList={nationalityList} cityId={cityId} cityList={cityList} cityListAll={cityListAll} countryFilterList={countryFilterList}
            nationality={nationality} industry={industry} industryList={industryList} industryFilterList={industryFilterList} field={field}
            fieldsList={fieldsList} visaTypeList={visaTypeList} foreignLanguagesList={foreignLanguagesList} foreignLanguagesList2={foreignLanguagesList2}
            foreignLanguagesList3={foreignLanguagesList3} foreignLanguage={foreignLanguage} foreignLanguageLevel={foreignLanguageLevel}
            foreignLanguage2={foreignLanguage2} foreignLanguageLevel2={foreignLanguageLevel2} foreignLanguage3={foreignLanguage3}
            foreignLanguageLevel3={foreignLanguageLevel3} foreignLanguageLevelList={foreignLanguageLevelList} foreignLanguageLevelList2={foreignLanguageLevelList2}
            foreignLanguageLevelList3={foreignLanguageLevelList3} foreignLanguageLevelAll={foreignLanguageLevelAll} foreignLanguageAll={foreignLanguageAll}
            currency={currency} currencyList={currencyList}
          />}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ManageJobs;
