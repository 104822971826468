

import React from 'react';
import Moment from 'moment';
import { useTranslation } from "react-i18next";
const Ago = ({ date }) => {
    const { t } = useTranslation("translation");
     
    let jobPostExpire = "";
        if (date) {
            let dateNow = new Date();
            let dateNeed = new Date(date);
            let postExpireTime = dateNeed.getTime() - dateNow.getTime();
            let days = Math.floor(postExpireTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((postExpireTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((postExpireTime % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((postExpireTime % (1000 * 60)) / 1000);
            if (Moment(new Date).format("YYYYMMDD") == Moment(date).format("YYYYMMDD")) {
                if (Moment(new Date).format("HH") == Moment(date).format("HH")) {
                    if (Moment(new Date).format("mm") == Moment(date).format("mm")) {
                        jobPostExpire =  t("Còn {{1}} giây để ứng tuyển",{1:seconds }); 
                    } else {
                        jobPostExpire = t("Còn {{1}} phút để ứng tuyển",{1:minutes }); 
                    }
                } else {
                    let hourExpired = 24 - Moment(new Date).format("HH");
                    jobPostExpire =t("Còn {{1}} giờ để ứng tuyển",{1:hourExpired }); 
                }
            } else {
                if (Moment(new Date).format("YYYYMMDD") > Moment(date).format("YYYYMMDD")) {
                    jobPostExpire = t("Hết hạn");
                } else {
                    (days == 0 && hours < 24) ? (jobPostExpire = t("Còn {{1}} giờ để ứng tuyển",{1:hours })) : (jobPostExpire = t("Còn {{1}} ngày để ứng tuyển",{1:days }));
                }
            }
            
        }
    return (
        <span className="text-muted mb-0">
                                            <i className="uil uil-clock-three text-primary me-1"></i>{jobPostExpire}
                                        </span>
    )
};

export default Ago;