import React from "react";
import { Font, Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { certificateOfEligibilityList } from "../../../../../common/dropdownList/dropdownData";
import fontNotoSansRegular from "../../../../../assets/fonts/ARIAL.TTF";
import fontNotoSansRegularItalic from "../../../../../assets/fonts/ARIALI.TTF";
import fontNotoSansBold from "../../../../../assets/fonts/ARIALBD.TTF";
import fontNotoSansBoldItalic from "../../../../../assets/fonts/ARIALBI.TTF";
import Moment from 'moment';


const TemplateBasic4 = (props) => {

    Font.register({ family: "NotoSansRegular", src: fontNotoSansRegular });
    Font.register({ family: "NotoSansRegularItalic", src: fontNotoSansRegularItalic });
    Font.register({ family: "NotoSansBold", src: fontNotoSansBold });
    Font.register({ family: "NotoSansBoldItalic", src: fontNotoSansBoldItalic });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#FFFFFF"
        },
        image: {
            height: 100,
            width: 80,
        },
        textTitle: {
            fontFamily: "NotoSansBold",
            fontSize: 11
        },
        textTitleView: {
            width: "10%",
            backgroundColor: "#8FBC8F",
            borderLeft: 2,
            borderLeftStyle: "dotted",
            borderRight: 2,
            borderRightStyle: "dotted",
            justifyContent: "center",
            textAlign: "center"
        },
        textCenter: {
            justifyContent: "center",
            textAlign: "center"
        },
        textLeft: {
            justifyContent: "center",
            textAlign: "left"
        }
    });

    return (
        <Document>
            <Page style={styles.page}>
                <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ flexDirection: "column", width: "700%" }}>
                        <View style={{ flexDirection: "row", paddingTop: 10, width: "75%", justifyContent: "center" }}>
                            <Text style={{ fontFamily: "NotoSansBold" }}>履歴書</Text>
                        </View>
                        <View style={{ flexDirection: "row", paddingLeft: 10, paddingTop: 5, width: "75%", justifyContent: "flex-start" }}>
                            <Text style={styles.textTitle}>■基本情報</Text>
                        </View>
                        <View style={{ flexDirection: "column", width: "70%", borderTop: 2, borderLeft: 2, borderRight: 2, marginLeft: 10 }}>
                            <View style={{ width: "100%", flexDirection: "row" }}>
                                <View style={[styles.textLeft, {
                                    width: "15%", borderRight: 2, backgroundColor: "#8FBC8F", borderRightStyle: "dotted", borderBottom: 2,
                                    borderBottomStyle: "dotted"
                                }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>フリガナ</Text>
                                </View>
                                <View style={[styles.textCenter, { width: "50%", borderRight: 2, borderRightStyle: "dotted", borderBottom: 2, borderBottomStyle: "dotted", height: 31 }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.otherName}</Text>
                                </View>
                                <View style={[styles.textLeft, {
                                    width: "15%", borderRight: 2, backgroundColor: "#8FBC8F", borderRightStyle: "dotted", borderBottom: 2,
                                    borderBottomStyle: "dotted"
                                }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>性別</Text>
                                </View>
                                <View style={[styles.textLeft, { width: "20%", borderBottom: 2, borderBottomStyle: "dotted" }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.gender}</Text>
                                </View>
                            </View>
                            <View style={{ width: "100%", flexDirection: "row" }}>
                                <View style={[styles.textLeft, {
                                    width: "15%", borderRight: 2, backgroundColor: "#8FBC8F", borderRightStyle: "dotted", borderBottom: 2,
                                    borderBottomStyle: "dotted"
                                }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>氏名</Text>
                                </View>
                                <View style={[styles.textCenter, { width: "50%", borderRight: 2, borderRightStyle: "dotted", borderBottom: 2, borderBottomStyle: "dotted", height: 31 }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.fullName}</Text>
                                </View>
                                <View style={[styles.textLeft, {
                                    width: "15%", borderRight: 2, backgroundColor: "#8FBC8F", borderRightStyle: "dotted", borderBottom: 2,
                                    borderBottomStyle: "dotted"
                                }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>配偶者</Text>
                                </View>
                                <View style={[styles.textLeft, { width: "20%", borderBottom: 2, borderBottomStyle: "dotted" }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.marriage}</Text>
                                </View>
                            </View>
                            <View style={{ width: "100%", flexDirection: "row" }}>
                                <View style={[styles.textLeft, {
                                    width: "15%", borderRight: 2, backgroundColor: "#8FBC8F", borderRightStyle: "dotted", borderBottom: 2,
                                    borderBottomStyle: "dotted"
                                }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>生年月日</Text>
                                </View>
                                <View style={[styles.textCenter, { width: "50%", borderRight: 2, borderRightStyle: "dotted", borderBottom: 2, borderBottomStyle: "dotted", height: 31 }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.birthDay}</Text>
                                </View>
                                <View style={[styles.textLeft, {
                                    width: "15%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted", borderBottom: 2,
                                    borderBottomStyle: "dotted"
                                }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>年齢</Text>
                                </View>
                                <View style={[styles.textCenter, { width: "20%", borderBottom: 2, borderBottomStyle: "dotted" }]}>
                                    <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.age}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View>
                        <Image
                            style={{ height: 120, width: 100, marginLeft: -135, marginTop: 20 }}
                            source={props.data.avatar}
                        />
                    </View>
                </View>

                <View style={{ flexDirection: "column", width: "97%", marginLeft: 10, marginTop: -3 }}>
                    <View style={{ width: "100%", flexDirection: "row", borderLeft: 2 }}>
                        <View style={[styles.textLeft, {
                            width: "14%", height: 30, backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted",
                            borderTop: 2, borderTopStyle: "dotted"
                        }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>現住所</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "80%", height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.address}</Text>
                        </View>
                        <View style={{ width: "37%", borderTop: 2, borderRight: 2 }}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}></Text>
                        </View>
                    </View>

                    <View style={{
                        width: "100%", flexDirection: "row", borderBottom: 2, borderBottomStyle: "dotted", borderTop: 2, borderTopStyle: "dotted",
                        borderLeft: 2, borderRight: 2, marginTop: -3
                    }}>
                        <View style={[styles.textLeft, { width: "14%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted", height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>在留資格</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "31%", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.visaHistories[0].visaType}</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "14%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>期間</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "30%", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 2 }]}>{props.data.visaHistories[0].visaFromDate} - {props.data.visaHistories[0].visaExpDate}</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "16%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>日本語能力</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "26%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.japaneseLevel}</Text>
                        </View>
                    </View>

                    <View style={{
                        width: "100%", flexDirection: "row", borderLeft: 2, borderRight: 2, marginTop: -3, borderBottom: 2
                    }}>
                        <View style={[styles.textLeft, { width: "14%", backgroundColor: "#8FBC8F", borderTop: 2, borderTopStyle: "dotted", borderRight: 2, borderRightStyle: "dotted", height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>所持資格</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "117%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.userSkills[0].labelField}</Text>
                        </View>
                    </View>

                    <View style={{ width: "100%", flexDirection: "row" }}>
                        <View style={{ height: 30, justifyContent: "center" }}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>■健康状態</Text>
                        </View>
                    </View>
                    <View style={{
                        width: "100%", flexDirection: "row", borderTop: 2, borderBottom: 2, borderLeft: 2, borderRight: 2, marginTop: -3
                    }}>
                        <View style={[styles.textCenter, { width: "10%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted", height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>身長[cm]</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.height}</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>体重[kg]</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.weight}</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>血液型</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.bloodType}</Text>
                        </View>
                        <View style={styles.textTitleView}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>利き手</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.handedness}</Text>
                        </View>
                        <View style={styles.textTitleView}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>視力</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "10%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.eyesight}</Text>
                        </View>
                    </View>

                    <View style={{ width: "100%", flexDirection: "row" }}>
                        <View style={{ height: 30, justifyContent: "center" }}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>■自己PR</Text>
                        </View>
                    </View>
                    <View style={{
                        width: "100%", flexDirection: "row", borderTop: 2, borderLeft: 2, borderRight: 2, marginTop: -3
                    }}>
                        <View style={[styles.textLeft, { width: "25%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted", height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>得意科目･分野</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "25%", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.favoriteSubjects}</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "25%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>性格</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "25%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.personality}</Text>
                        </View>
                    </View>
                    <View style={{
                        width: "100%", flexDirection: "row", borderTop: 2, borderTopStyle: "dotted", borderBottom: 2, borderLeft: 2, borderRight: 2, marginTop: -3
                    }}>
                        <View style={[styles.textLeft, { width: "25%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted", height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>長所</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "25%", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.strongPoints}</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "25%", backgroundColor: "#8FBC8F", borderRight: 2, borderRightStyle: "dotted" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>短所</Text>
                        </View>
                        <View style={[styles.textLeft, { width: "25%" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{props.data.weakPoints}</Text>
                        </View>
                    </View>

                    <View style={{ width: "100%", flexDirection: "row" }}>
                        <View style={{ height: 30, justifyContent: "center" }}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>■学歴</Text>
                        </View>
                    </View>
                    <View style={{
                        width: "100%", flexDirection: "row", border: 2, marginTop: -3
                    }}>
                        <View style={[styles.textCenter, { width: "15%", backgroundColor: "#8FBC8F", borderRight: 2, height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>入学年/月</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "15%", borderRight: 2, backgroundColor: "#8FBC8F" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>卒業年/月</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "30%", backgroundColor: "#8FBC8F", borderRight: 2 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>学校名</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "25%", backgroundColor: "#8FBC8F", borderRight: 2 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>専攻</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "15%", backgroundColor: "#8FBC8F" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>状態</Text>
                        </View>
                    </View>
                    {
                        props.data.userEducations.map((item, key) => {
                            return (
                                <View style={{
                                    width: "100%", flexDirection: "row", borderLeft: 2, borderRight: 2, borderBottom: 2, marginTop: -3
                                }}>
                                    <View style={[styles.textCenter, { width: "15%", borderRight: 2, height: 30 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.enrollmentMonth}</Text>
                                    </View>
                                    <View style={[styles.textCenter, { width: "15%", borderRight: 2, height: 30 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.graduationMonth}</Text>
                                    </View>
                                    <View style={[styles.textLeft, { width: "30%", borderRight: 2, height: 30 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.detail}</Text>
                                    </View>
                                    <View style={[styles.textLeft, { width: "25%", borderRight: 2, height: 30 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.major}</Text>
                                    </View>
                                    <View style={[styles.textLeft, { width: "15%", height: 30 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.graduateStatus.label}</Text>
                                    </View>
                                </View>
                            )
                        })
                    }

                    <View style={{ width: "100%", flexDirection: "row" }}>
                        <View style={{ height: 30, justifyContent: "center" }}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>■職歴</Text>
                        </View>
                    </View>
                    <View style={{
                        width: "100%", flexDirection: "row", border: 2, marginTop: -3
                    }}>
                        <View style={[styles.textCenter, { width: "15%", backgroundColor: "#8FBC8F", borderRight: 2, height: 30 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>開始年/月</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "15%", borderRight: 2, backgroundColor: "#8FBC8F" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>終了年/月</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "35%", backgroundColor: "#8FBC8F", borderRight: 2 }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>会社名</Text>
                        </View>
                        <View style={[styles.textCenter, { width: "35%", backgroundColor: "#8FBC8F" }]}>
                            <Text style={[styles.textTitle, { paddingLeft: 5 }]}>仕事内容</Text>
                        </View>
                    </View>
                    {
                        props.data.userWorkExps.map((item, key) => {
                            return (
                                <View style={{
                                    width: "100%", flexDirection: "row", borderLeft: 2, borderRight: 2, borderBottom: 2, marginTop: -3
                                }}>
                                    <View style={[styles.textCenter, { width: "15%", borderRight: 2, height: 30 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.startMonth}</Text>
                                    </View>
                                    <View style={[styles.textCenter, { width: "15%", borderRight: 2 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.endMonth}</Text>
                                    </View>
                                    <View style={[styles.textLeft, { width: "35%", borderRight: 2 }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.company}</Text>
                                    </View>
                                    <View style={[styles.textLeft, { width: "35%" }]}>
                                        <Text style={[styles.textTitle, { paddingLeft: 5 }]}>{item.jobDesc}</Text>
                                    </View>
                                </View>
                            )
                        })
                    }
                </View>
            </Page>
        </Document>
    );
};

export default TemplateBasic4;