import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getMyApplied } from "../../../api/apiJob";
import Section from "../../../pages/Component/PageHeader";
import JobAllList from "../../../common/jobAllList/JobAllList";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import PlaceholderJobFilter from "../../../common/placeholder/PlaceholderJobFilter";
import Alerts from "../../ExtraPages/Components/Alerts";
import AlertsSuccess from "../../ExtraPages/Components/AlertsSuccess";
import Spinners from "../../ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import PlaceholderLoaddingJob from "../../../common/placeholder/PlaceholderLoaddingJob";
import { convertExperience } from "../../../common/checkValidate/convertExperience";
import { GetCountryList } from "../../../api/apiCountry";
import JobItemComponent from "../../../common/jobAllList/JobItemComponent";
import NhaHang from "../../../assets/images/industry/nhahang.webp";
import KhachSan from "../../../assets/images/industry/khachsan.webp";
import CheBienThucPham from "../../../assets/images/industry/chebienthucpham.webp";
import XayDung from "../../../assets/images/industry/xaydung.webp";
import DieuDuong from "../../../assets/images/industry/dieuduong.webp";
import CoKhi from "../../../assets/images/industry/cokhi.webp";
import DienTu from "../../../assets/images/industry/diendientu.webp";
import NongNghiep from "../../../assets/images/industry/nongnghiep.webp";
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";

import VietNam from "../../../assets/images/flags/vietnam.jpg";
import Japan from "../../../assets/images/flags/japan.jpg";
import Korea from "../../../assets/images/flags/korea.jpg";
import Germany from "../../../assets/images/flags/germany.jpg";
import Singapore from "../../../assets/images/flags/singapore.jpg";
import Qatar from "../../../assets/images/flags/qatar.jpg";
import Cyprus from "../../../assets/images/flags/cyprus.jpg";
import Taiwan from "../../../assets/images/flags/taiwan.jpg";
const JobMyApplied = () => {

  const { t } = useTranslation("translation");
  document.title =
    "Việc làm đang ứng tuyển";

  const companyName = getStorage(STORAGE_KEYS.FULL_NAME);
  const companyImage = getStorage(STORAGE_KEYS.AVATAR);

  let [jobListing, setJobListing] = useState([]);
  let [alertNoData, setAlertNoData] = useState('');

  let [countryList, setCountryList] = useState();
  let [industryList, setIndustryList] = useState();
  let [countriesArr, setCountriesArr] = useState([]);
  let [industryArr, setIndustryArr] = useState([]);
  let [countryData, setCountryData] = useState([]);
  let [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    GetCountryList().then((result) => {
      if (result.country) {
        setCountryData(result.country);
      }
      if (result.category) {
        setCategoryData(result.category);
      }
    });
  }, []);

  useEffect(() => {
    getMyApplied().then((res) => {
      const job = [];
      if (res.length) {
        res.map((item, key) => {
          job.push({
            id: item.job.id,
            cityId: item.job.cityId,
            catId: item.job.catId,
            jobTitle: item.job.title,
            company: item.job.company,
            workLocationAddress: item.job.workLocationAddress,
            dateNeeded: item.job.dateNeeded,
            jobType: item.job.jobType,
            salary:  numberFormat(item.job.salaryFrom) + ' - ' + numberFormat(item.job.salaryTo) + ' ' + item.job.currency ,
            addclassNameBookmark: false,
            badges: [],
            experience: convertExperience(item.job.experienceRequired),
            featured: item.job.featured,
          });
        });
        setJobListing(job);
        job.length == 0 ? setAlertNoData('Không có việc làm đang ứng tuyển!') : setAlertNoData('');
      } else {
        setAlertNoData('Không có việc làm đang ứng tuyển!');
        setJobListing([]);
      }
    });
  }, []);


  return (
    <React.Fragment>
      <Section title="Việc làm đang ứng tuyển" />
      <section className="section">
        <Container>
          <Row>
            <Col lg={12}>
              {alertNoData && <div className="section-title text-center">
                <h3 className="title">{alertNoData}</h3>
              </div>}
              {(!jobListing.length && !alertNoData) ? (<PlaceholderJobFilter />) : 
              
              <Row className="align-items-center">
                {jobListing && jobListing.map((jobDetail, key) => (
                    <Col lg={12} md={12} key={key}>
                      <JobItemComponent jobDetail={jobDetail} myApplied={true} countryData={countryData} categories={categoryData} />
                    </Col>
                ))}
              </Row>
              
              
              }
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};

export default JobMyApplied;
