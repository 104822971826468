import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { checkEmailFormat } from "../../common/checkValidate/checkEmail";
import { forgotPassword } from "../../api/apiAccount";
import resetPasswordImage from "../../assets/images/auth/reset-password.webp";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import ScrolltoTop from "../../components/ScrolltoTop";
import Spinners from "../../pages/ExtraPages/Components/Spinners";
import Alerts from "../../pages/ExtraPages/Components/Alerts";
import { MESSAGE_VALIDATE } from "../../common/constants/Messages";


const ResetPassword = () => {
  const { t } = useTranslation("translation");
  document.title = t("signin.quenmatkhau");

  const logo = 'https://wolrdjobstorage.blob.core.windows.net/assets/top_logo.png';

  let [email, setEmail] = useState('');
  let [emailErr, setEmailErr] = useState('');
  let [valid, setValid] = useState(true);
  let [loading, setLoading] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);

  const didMount = useRef(false);

  const navigate = useNavigate();

  const handleEmail = event => {
    setEmail(event.target.value);
  }

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate();
  }, [email]);

  const handleRequest = () => {
    setLoading(true);
    setAlertDanger(false);
    forgotPassword(email).then((res) => {
      if (res.status === 200) {
        navigate("/signin");
      } else {
        setAlertDanger(true);
      }
      setLoading(false);
    });
  }

  const checkValidate = () => {
    if (!checkEmailFormat(email)) {
      setEmailErr(t("Định dạng Email chưa chính xác"));
      setValid(true);
    } else {
      setEmailErr('');
      setValid(false);
    }
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={logo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                src={logo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          {loading && <Spinners />}
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="text-center mb-4">
                              <h5>{t("signin.quenmatkhau")}</h5>
                            </div>
                            <div className="auth-form text-white">
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                {" "}{t("forgetpass.nhapemail")}{" "}
                              </div>
                              <div className="mb-4">
                                <label className="form-label" htmlFor="email">
                                  {t("signup.email")}
                                </label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder={t("signup.nhapemail")}
                                  onChange={handleEmail}
                                  value={email}
                                />
                                <label style={{ color: 'red', fontSize: 14 }}>{emailErr}</label>
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  className="btn btn-white w-100"
                                  onClick={handleRequest}
                                  disabled={valid}
                                >
                                  {t("forgetpass.guiyeucau")}
                                </button>
                              </div>
                            </div>
                            <div className="mt-5 text-center text-white-50">
                              <p>
                                <Link
                                  to="/signin"
                                  className="fw-medium text-white text-decoration-underline"
                                >
                                  {" "}{t("forgetpass.guiyeucau")}{" "}
                                </Link>
                              </p>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                      {alertdanger && <Alerts message={MESSAGE_VALIDATE.forgot_pass} />}
                    </Card>
                  </Col>
                </Row>
                <ScrolltoTop />
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
