import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import　ago from '../../components/ago'
import Ago from "../../components/ago";
const JobAllList = ({ jobList, myApplied }) => {

    let jobDetail = '/job/';

    return (
        <React.Fragment>
            {jobList && jobList.map((jobListDetails, key) => (
                <div
                    key={key}
                    className={
                        jobListDetails.addclassNameBookmark === true
                            ? "job-box bookmark-post card mt-4"
                            : "job-box card mt-4"
                    }
                >
                    {
                        jobListDetails.iconJobPost && <div className="bookmark-label text-center">
                            <Link to="#" className="text-white align-middle">
                                <img
                                    src={jobListDetails.iconJobPost}
                                    alt=""
                                    className="img-fluid rounded-3"
                                    style={{ width: '50px', height: '40px', objectFit: 'cover' }}
                                />
                            </Link>
                        </div>
                    }
                    <div className="p-4">
                        <Row className="align-items-center">
                            <Col md={2}>
                                <div className="text-center mb-4 mb-md-0">
                                    <Link to={jobDetail + jobListDetails.id}>
                                        <img
                                            src={jobListDetails.companyImg}
                                            alt=""
                                            className="img-fluid rounded-3"
                                            style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                                        />
                                    </Link>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div className="mb-2 mb-md-0">
                                    <h5 className="fs-18 mb-1">
                                        <Link to={jobDetail + jobListDetails.id} className="text-dark">
                                            {jobListDetails.jobTitle}
                                        </Link>
                                    </h5>
                                    <p className="text-muted fs-14 mb-0">
                                        {jobListDetails.companyName}
                                    </p>
                                </div>
                            </Col>

                            <Col md={2}>
                                <div className="d-flex mb-2">
                                    <div className="flex-shrink-0">
                                        <i className="mdi mdi-map-marker text-primary me-1"></i>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <img src={jobListDetails.countryImage} style={{ marginTop: 3 }} height="18" />
                                        <p className="text-muted mb-0" style={{ paddingLeft: 5 }}>{jobListDetails.cityName}</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={3}>
                                <div>
                                    <p className="text-muted mb-2">
                                        {jobListDetails.salary}
                                    </p>
                                </div>
                            </Col>

                            <Col md={2}>
                                <div>
                                    <span
                                        className={
                                            jobListDetails.fullTime === true
                                                ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                                : jobListDetails.partTime === true
                                                    ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                                    : jobListDetails.freelancer === true
                                                        ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                                        : ""
                                        }
                                    >
                                        {jobListDetails.jobTypeText}
                                    </span>

                                    {(jobListDetails.badges || []).map((badgeInner, key) => (
                                        <span
                                            className={
                                                "badge " + badgeInner.badgeclassName + " fs-13 mt-1"
                                            }
                                            key={key}
                                        >
                                            {badgeInner.badgeName}
                                        </span>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="p-3 bg-light">
                        <Row>
                            <Col md={4}>
                                <div>
                                    <p className="text-muted mb-0">
                                        <span className="text-dark">Kinh nghiệm :</span>{" "}
                                        {jobListDetails.experience}
                                    </p>
                                </div>
                            </Col>
                            <Col lg={3} md={5}>
                            </Col>
                            <Col lg={3} md={5}>
                                { }
                                <div>
                                    <Ago date={jobListDetails.dateNeeded} />
                                </div>
                            </Col>
                            {!myApplied && <Col lg={2} md={3}>
                                <div className="text-start text-md-end">
                                    <Link to={jobDetail + jobListDetails.id} className="primary-link">
                                        Ứng tuyển <i className="mdi mdi-chevron-double-right"></i>
                                    </Link>
                                </div>
                            </Col>}
                        </Row>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}

export default JobAllList;